<template>
  <div>
    <h2 class="p-l-20 p-t-10">站点投放量top20</h2>
    <div style="width:100%;height:250px;" id="ownerStationChartReport"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getStorage } from '@/utils/storage'
import { countResourceSaleByPackage } from '@/api/dw/publisher'

export default {
  data () {
    return {
      dataSourcePie: null
    }
  },
  mounted () {
    this.loadReportData()
  },
  methods: {
    loadReportData () {
      const postData = {
        endDate: this.searchSchedule.endDate,
        startDate: this.searchSchedule.startDate,
        gbType: 'station_name',
        packageIds: this.gbId
      }

      countResourceSaleByPackage(postData).then(res => {
        const xData = []
        const yData = []
        for (let index = 0; index < 20; index++) {
          if (index < res.length) {
            xData.push(res[index].name)
            yData.push(res[index].number)
          }
        }

        this.initReportChart(xData, yData)
      })
    },
    initReportChart (xData, yData) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('ownerStationChartReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('ownerStationChartReport'))

          const option = {
            color: ['#2b85e4'],
            xAxis: {
              type: 'category',
              data: xData,
              axisLabel: {
                color: '#c5c8ce'
              }
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                show: false
              },
              splitLine: {
                lineStyle: {
                  opacity: 0.1
                }
              }
            },
            grid: {
              top: '10%',
              left: '5%',
              right: '5%',
              bottom: '10%',
              containLabel: true
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
                crossStyle: {
                  color: '#999'
                }
              }
            },
            series: [
              {
                data: yData,
                type: 'bar'
              }
            ]
          }

          this.dataSourcePie.setOption(option, true)
          const that = this
          window.addEventListener('resize', function () {
            that.dataSourcePie.resize()
          })
        }
      })
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    gbId () {
      return this.$store.state.situationWeb.gbId
    },
    reportFontColor () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark' ? '#fff' : '#000'
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadReportData()
      }
    },
    gbId () {
      this.loadReportData()
    }
  }
}
</script>
